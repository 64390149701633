<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">{{ twoTypeInfo.name }}</div>
    <div class="titleBox">
      <div
        class="titleBox"
        v-for="(item, index) in twoTypeInfo.contentList"
        :key="index"
      >
        <div class="titleBox_title">{{ item.title }}</div>
        <div class="titleBox_content">
          <p v-for="(item2, index2) in item.textList" :key="index2">
            {{ item2.content }}
            <br /><br />
          </p>
        </div>
      </div>
    </div>
    <!-- 产品product -->
    <div class="product">
      <div class="product_title">PRODUCTS AND SOLUTIONS</div>
      <div class="product_main">
        <div
          class="product_main_box"
          v-for="item in twoTypeInfo.productList"
          :key="item.id"
          @click="productInfo(item.id)"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.name }}</div>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    //面包屑导航

    if (this.$route.query.id) {
      let id = this.$route.query.id;
      this.applicationTwoTypeInfo(id);
      sessionStorage.setItem("TowTypeListId", id);
    } else {
      this.applicationTwoTypeInfo(sessionStorage.getItem("TowTypeListId"));
    }
  },
  data() {
    return {
      breadcrumbList: [],
      twoTypeInfo: {}, //
    };
  },
  watch: {
    $route(to) {
      if (to.query.id) {
        let id = to.query.id;
        this.applicationTwoTypeInfo(id);
        sessionStorage.setItem("TowTypeListId", id);
      }
    },
  },
  methods: {
    //获取二级分类-详情
    applicationTwoTypeInfo(id) {
      api.applicationTwoTypeInfo({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.twoTypeInfo = data.data;
          this.breadcrumbList = [
            { path: "/", title: "HOME" },
            { path: "industries", title: "INDUSTRIES & APPLICATIONS" },
            {
              path: "appretail",
              title: sessionStorage.getItem("oneTypeListName"),
            },
            { path: "", title: this.twoTypeInfo.name },
          ];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //产品详情的跳转
    productInfo(id) {
      document.documentElement.scrollTop = 0;
      this.$router.push({
        path: "/retaltInfo",
        query: { id },
      });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.titleBox {
  margin: 33px auto 37px;
  width: 1136px;
  line-height: 24px;
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #5a5b5e;
  .titleBox_title {
    font-size: 36px;
    line-height: 36px;
    font-family: Arial;
    font-weight: normal;
    color: #004a9c;
    padding: 45px 0;
  }
}
.product {
  .product_title {
    text-align: center;
    height: 53px;
    padding: 40px 0 50px;
    font-size: 50px;
    font-family: Arial;
    font-weight: bold;
    color: #004a9c;
  }
  .product_main {
    width: 1156px;
    margin: 0 auto;

    display: flex;

    flex-wrap: wrap;
    .product_main_box {
      position: relative;
      height: 262px;
      width: 207px;
      margin-bottom: 30px;
      cursor: pointer;
      img {
        width: 207px;
        height: 202px;
        display: block;
      }
      div {
        width: 177px;
        height: 60px;
        background: #f0f2f7;
        text-align: center;
        font-size: 16px;
        font-family: Arial;
        font-weight: bold;
        color: #5a5b5e;
        line-height: 60px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 0 15px;
      }
    }
    .product_main_box:hover {
      span {
        position: absolute;
        width: 207px;
        height: 202px;
        display: block;
        background: #172f66;
        left: 0;
        top: 0;
        opacity: 0.2;
      }
      div {
        color: #ffffff;
        background: #004a9c;
      }
    }
    .product_main_box:not(:nth-of-type(5n)) {
      margin-right: 30px;
    }
  }
}
</style>